/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button, Spin, Avatar } from "antd";
import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  UserOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// Importar Reactour
import Tour from "reactour";

import AppTitle from "../../atoms/Title/AppTitle";
import AppModal from "../../molecules/Modal/Modal";
import ROUTES from "../../../constant/router";
import TableTemplate from "../TableTemplate";
import document from "../../../api/document";
import useApi from "../../../api/useApi";
import transformToSelectArray from "../../../helpers/transformToSelectArray";
import { useAuth } from "../../../contexts/AuthContext";
import DocumentFormTemplate from "./DocumentFormTemplate";
import DocumentRequest from "../../../api/DocumentRequest";
import transformToDateFormat from "../../../helpers/transformToDateFormat";
import convertDate from "../../../helpers/dateUtil";
import formatSize from "../../../helpers/formatSize";
import isObject from "../../../helpers/isObject";
import ModalConfirm from "../../molecules/Modal/ModalConfirm";
import downloadFile from "../../../helpers/downloadFile";
import validatePermissionUserOwner from "../../../helpers/validatePermissionOwnerUser";
import FormAutoSearch from "../../molecules/FormAutoSearch/FormAutoSearch";
import transformToTreeSelectRegions from "../../../helpers/transformToTreeSelectRegions";
import setProperties from "../../../helpers/setProperties";
import listCodification from "../../../constant/listCodification";

// Define los pasos del tour
const tourSteps = [
  {
    selector: ".tour-step-1",
    content: "Este es el título de la página.",
  },
  {
    selector: ".tour-step-2",
    content: "Aquí puedes buscar documentos.",
  },
  {
    selector: ".tour-step-3",
    content: "Estos son los documentos cargados.",
  },
];

const DocumentListTemplate = ({ documentView }) => {
  const [loading, setLoading] = useState({
    general: false,
    list: false,
    steps: false,
    descriptionModal: false,
    description: false,
    download: false,
    delete: false,
    search: false,
  });
  const [dataDescription, setDataDescription] = useState();
  const [modalActive, setModalActive] = useState(false);
  const [modalActiveConfirm, setModalActiveConfirm] = useState(false);
  const getAllDocuments = useApi(document.getAllDocuments);
  const getDocTypes = useApi(document.getDocumentTypes);
  const getDescriptionDoc = useApi(document.getDescriptionDoc);
  const updateDescriptionDoc = useApi(document.updateDescriptionDoc);
  const getUrlDocument = useApi(document.getUrlDocument);
  const downloadDocumentByUrl = useApi(document.downloadDocumentByUrl);
  const downloadPdf = useApi(document.downloadPdf);
  const isFavoriteDocument = useApi(document.UpdateFavoriteDocument);
  const deleteDocument = useApi(document.deleteDocument);
  // const {
  //   request: getMunicipalities,
  //   data: municipalities,
  //   setData: setDataMunicipalities,
  // } = useApi(DocumentRequest.getMunicipalities);
  const getMunicipalityForRegion = useApi(
    DocumentRequest.getMunicipalitiesSubRegions
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [docTypes, setDocTypes] = useState();
  const [data, setData] = useState();
  const { setMessage, currentUser } = useAuth();
  const [action, setAction] = useState("");
  const [listMunicipalities, setListMunicipalities] = useState([]);
  const [listColumns, setListColumns] = useState([]);
  const [idDocument, setIdDocument] = useState();
  const [infoFile, setInfoFile] = useState({ name: null, extension: null });
  const [filterTypeDoc, setFilterTypeDoc] = useState([]);
  const [metadata, setMetadata] = useState({
    total: 10,
    page: 1,
    page_size: 10,
  });
  const [ownerView, setOwnerView] = useState(false);
  const { ValidatePermissionUserOwner } = validatePermissionUserOwner;
  const [isSearch, setIsSearch] = useState(false);
  const [modalActiveEliminar, setModalActiveEliminar] = useState(false);
  // const docTypeWeb = "6647566b36ea2fe2ec662a9a";
  // const [typeSource, setTypeSource] = useState("");

  // Estado para controlar el tour
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [columnSelected, setColumnSelected] = useState({});
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  const reloadListDocs = () => {
    setLoading((prev) => ({ ...prev, list: true }));
    setOwnerView(false);
    setData([]);
    getAllDocuments.request(metadata.page_size, metadata.page, documentView);
  };

  useEffect(() => {
    getDocTypes.request();
    setLoading((prev) => ({ ...prev, list: true }));
    setListColumns([]);
    if (!isSearch) {
      reloadListDocs();  
    }else {
      getAllDocuments.request(metadata.page_size, metadata.page, documentView, query);
      
    }
  }, [metadata.page, documentView]);

  /*   useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    reloadListDocs();
    // getDocTypes.request();
    // getMunicipalities(52);
    // getMunicipalityForRegion.request();

    setLoading((prev) => ({ ...prev, list: true }));
    setListColumns([]);
  }, [metadata.page ]); */

  const handlePageChange = (page) => setMetadata((prev) => ({ ...prev, page }));

  const onDownloadDocument = (record) => {
    setLoading((prev) => ({ ...prev, download: true }));
    if (record.fileExtension === ".pdf") {
      downloadPdf.request(record.key);
    } else {
      getUrlDocument.request(record.key);
    }
  };

  const onView = (key) => {
    const path = ROUTES.GENERAL.DOCUMENT_INFO.replace(":key", key);
    navigate(path);
  };

  const onEdit = (key) => {
    setCurrentStep(0);
    getDescriptionDoc.request(key);
    setIdDocument(key);
    setAction("edit");
    setLoading((prev) => ({ ...prev.description, [key]: true }));
  };

  const onFaviroteDocument = (record) => {
    isFavoriteDocument.request(record.key, { isFavorite: !record.isFavorite });
    setLoading((prev) => ({ ...prev, list: true }));
  };

  const onClickRow = (obj) => {
    setColumnSelected(obj);

    // eslint-disable-next-line dot-notation
    const id = obj["_id"] || obj.id;
    getDescriptionDoc.request(id);
    setLoading((prev) => ({ ...prev.description, [id]: true }));
    setLoading((prev) => ({ ...prev, descriptionModal: true }));
    setIdDocument(id);
    setAction("view");
  };

  const closeModal = () => {
    if (currentStep === 1) {
      setModalActiveConfirm(true);
    } else {
      setModalActive(false);
      setLoading((prev) => ({ ...prev, general: false, steps: false }));
    }
  };

  const onDeleted = (key) => {
    setLoading((prev) => ({
      ...prev,
      delete: { ...prev.delete, [key]: true },
    }));
    setModalActiveEliminar(true);
    setIdDocument(key);
  };

  const closeConfirmModal = () => setModalActiveConfirm(false);
  const closeEliminarModal = () => setModalActiveEliminar(false);

  const confirmCloseModal = () => {
    setModalActive(false);
    setModalActiveConfirm(false);
    setLoading((prev) => ({ ...prev, general: false, steps: false }));
  };
  const EliminarCloseModal = () => {
    setModalActiveEliminar(false);
    setLoading((prev) => ({ ...prev.delete, [idDocument]: false }));
    setLoading((prev) => ({ ...prev, list: true }));
    const auxData = {
      isDeleted: true,
    };
    updateDescriptionDoc.request(idDocument, auxData);
  };

  const openModal = () => {
    setAction("upload");
    setLoading((prev) => ({ ...prev, general: true }));
    setModalActive(true);
    setCurrentStep(0);
  };

  const handlerSubmitDescription = (val) => {
    const { name, description, municipalityColumn, dateColumn } = val;
    let munColumnAux = municipalityColumn || null;
    let dateColumnAux = dateColumn || null;
    const municipality = val?.municipality || [];
    let municipalityCode = null;
    const dateRange = val?.dateRange
      ? transformToDateFormat(val?.dateRange)
      : null;
    const urlWebSource = val?.urlWebSource || null;
    const keyWords = val?.keyWords || [];
    const cutOffDate = val?.cutOffDate
      ? transformToDateFormat(val?.cutOffDate)
      : null;

    if (action === "edit") {
      if (municipalityColumn === "0") {
        munColumnAux = null;
      } else {
        municipalityCode = val?.municipalityCode || null;
      }

      if (dateColumn === "0") {
        dateColumnAux = null;
      }
    }
    const auxData = {
      name,
      description,
      urlWebSource,
      keyWords,
      cutOffDate,
      locationData: {
        municipalityColumn: munColumnAux,
        municipalityCode,
        municipality,
        dateColumn: dateColumnAux,
        dateRange,
      },
    };

    updateDescriptionDoc.request(idDocument, auxData);
    setLoading((prev) => ({ ...prev.description, [idDocument]: true }));

    setLoading((prev) => ({ ...prev, descriptionModal: true, list: true }));
  };

  const handlerSearch = async (val) => {
    if (val !== "") {
      setLoading((prev) => ({ ...prev, search: true }));
      setQuery(val);
      getAllDocuments.request(metadata.page_size, 1, documentView, val);
      setIsSearch(true);
    }
  };

  const onResetSearch = () => {
    // setLoadingSearch(true);
    setQuery();
    setIsSearch(false);
    setMetadata((prev) => ({ ...prev, page: 1 }));
    // reloadListDocs();
    setLoading((prev) => ({ ...prev, list: true }));
  };

  useEffect(() => {
    if (getDocTypes.data) {
      const arraySelect = transformToSelectArray(
        getDocTypes.data,
        ["name", "document_type", "documentType"],
        "id"
      );
      const aux = transformToSelectArray(
        getDocTypes.data,
        ["name", "document_type", "documentType"],
        "name"
      );
      setFilterTypeDoc(aux);
      setDocTypes(arraySelect);
      getDocTypes.setData();
    }

    if (getAllDocuments.data) {
      const documents = getAllDocuments.data.data;
      const { items, ...rest } = documents;
      let auxData;
      if (documentView === "my-files") {
        auxData = items.map((item) => ({
          ...item,
          key: item._id || item.id,
          docType: item.docType?.name || "",
          createdAt: convertDate(item.createdAt),
        }));
      } else {
        auxData = items.map((item) => ({
          ...item,
          key: item._id || item.id,
          docType: item.docType?.name || "",
          createdAt: convertDate(item.createdAt),
          owner: setProperties(item.userDetails, currentUser)?.name,
        }));
      }
      setMetadata(rest);
      setData(auxData);
      setLoading((prev) => ({ ...prev, list: false, search: false }));
      getAllDocuments.setData();
    }

    if (getDescriptionDoc.data) {
      const auxData = getDescriptionDoc.data.data;
      // if (auxData.status === "active") {

      if (action === "view") {
        if (
          auxData.fileExtension === "web" ||
          auxData.fileExtension === "pdf" ||
          auxData.multipleSheets === false
        ) {
          onView(idDocument);
        } else {
          onView(
            auxData?.listSheets?.length > 0
              ? auxData.sheetsList[0]?._id
              : idDocument
          );
        }
      } else if (action === "edit") {
        setModalActive(true);
        setDataDescription(auxData);
      }
      let auxColumns = [];
      if (auxData.metadata && isObject(auxData.metadata)) {
        auxColumns = transformToSelectArray(
          auxData.metadata.columns,
          "name",
          "name"
        );
      }
      setListColumns(auxColumns);
      /* } else {
        setMessage({
          type: "info",
          title: "Procesando",
          description:
            "Por favor tenga paciencia los datos de este archivo se siguen procesando.",
        });
      } */
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
      setLoading((prev) => ({ ...prev.description, [idDocument]: false }));
      // setLoading((prev) => ({
      //   ...prev,
      //   description: { ...prev.description, [idDocument]: false },
      // }));
      getDescriptionDoc.setData();
    }

    if (getUrlDocument.data) {
      downloadDocumentByUrl.request(getUrlDocument.data.data);
      getUrlDocument.setData();
    }
    if (downloadDocumentByUrl.data) {
      setLoading((prev) => ({ ...prev, download: false }));
      downloadFile(
        downloadDocumentByUrl.data,
        infoFile.name,
        infoFile.extension
      );
      // downloadFile(downloadDocumentByUrl.data, infoFile.name, ".csv");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });
      downloadDocumentByUrl.setData();
    }
    if (downloadPdf.data) {
      downloadFile(downloadPdf.data, infoFile.name, ".pdf");
      setMessage({
        type: "success",
        title: "Resultado",
        description: "El archivo fue descargado exitosamente.",
      });
      setLoading((prev) => ({ ...prev, download: false }));
      downloadPdf.setData();
    }
    /*     if (getDocumentByKeyWord.data) {
      const documents = getDocumentByKeyWord.data.data;
      const { items, ...rest } = documents;
      const auxData = documents.map((item) => ({
        ...item,
        key: item._id || item.id,
        docType: item.docType?.name || "",
        createdAt: convertDate(item.createdAt),
        owner: setProperties(item.userDetails, currentUser.email).name,
      }));

      setMetadata(rest);
      setData(auxData);
      getDocumentByKeyWord.setData();
    } */

    if (isFavoriteDocument.data) {
      if (isFavoriteDocument.data.data.isFavorite) {
        setMessage({
          type: "success",
          title: "Resultado",
          description: "El documento fue agregado a favoritos.",
        });
        // setIsFavorite(true);
      } else {
        setMessage({
          type: "success",
          title: "Resultado",
          description: "El documento fue eliminado de favoritos.",
        });
        // setIsFavorite(false);
      }
      isFavoriteDocument.setData();
      reloadListDocs();
    }
  }, [
    getDocTypes.data,
    getAllDocuments.data,
    getDescriptionDoc.data,
    getUrlDocument.data,
    downloadDocumentByUrl.data,
    downloadPdf.data,
    deleteDocument.data,
    isFavoriteDocument.data,
  ]);

  useEffect(() => {
    // if (municipalities) {
    //   const aux = transformToSelectArray(
    //     municipalities,
    //     "municipio",
    //     "municipio"
    //   );
    //   const sortedData = [...aux].sort((a, b) =>
    //     a.label.localeCompare(b.label)
    //   );
    //   setListMunicipalities(sortedData);
    //   setDataMunicipalities();
    // }
    if (getMunicipalityForRegion.data) {
      const aux = transformToTreeSelectRegions(
        getMunicipalityForRegion.data,
        "subRegion",
        "subRegion"
      );
      setListMunicipalities(aux);
      getMunicipalityForRegion.setData();
    }

    if (updateDescriptionDoc.data) {
      if (updateDescriptionDoc.data.data) {
        if (updateDescriptionDoc.data.data.isDeleted) {
          setMessage({
            type: "success",
            title: "Resultado",
            description: "El documento fue eliminado correctamente.",
          });
        } else {
          setMessage({
            type: "success",
            title: "Resultado",
            description: "El documento se ha actualizado correctamente",
          });
        }
        setModalActive(false);
        reloadListDocs();
        setLoading((prev) => ({ ...prev.description, [idDocument]: true }));

        setLoading((prev) => ({ ...prev, general: false }));
        updateDescriptionDoc.setData();
      }
    }
  }, [
    // municipalities,
    updateDescriptionDoc.data,
    getMunicipalityForRegion.data,
  ]);

  useEffect(() => {
    if (getDescriptionDoc.error) {
      setMessage({
        type: "info",
        title: "Procesando",
        description:
          "Por favor tenga paciencia los datos de este archivo se siguen procesando.",
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false }));
    }
    if (getAllDocuments.error) {
      if (getAllDocuments.error === "No files found") {
        setMessage({
          type: "info",
          title: "Información",
          description: "No se encontraron archivos cargados.",
        });
      } else {
        setMessage({
          type: "error",
          title: "Error",
          description:
            "Hubo un error al intentar obtener los datos. Vuelva a intentarlo.",
        });
      }
      setLoading((prev) => ({ ...prev, list: false, search: false }));
    }

    if (updateDescriptionDoc.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar guardar la descripción. Por favor inténtelo nuevamente.",
      });
      setLoading((prev) => ({ ...prev, descriptionModal: false, list: false }));
      setLoading((prev) => ({ ...prev.description, [idDocument]: true }));
      // setLoading((prev) => ({
      //   ...prev,
      //   description: { ...prev.description, [idDocument]: false },
      // }));
    }
    if (getUrlDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
      });
      setLoading((prev) => ({ ...prev, download: false }));
    }
    if (downloadPdf.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar descargar el archivo. Inténtelo de nuevo mas tarde.",
      });
      setLoading((prev) => ({ ...prev, download: false }));
    }

    if (deleteDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar eliminar el archivo. Inténtelo de nuevo mas tarde.",
      });
      setLoading((prev) => ({
        ...prev.delete,
        [idDocument]: false,
        list: false,
      }));
    }
    if (isFavoriteDocument.error) {
      setMessage({
        type: "error",
        title: "Error",
        description:
          "Hubo un error al intentar agregar el archivo como favorito. Inténtelo de nuevo más tarde.",
      });
      setLoading((prev) => ({ ...prev, list: false }));
    }
    if (downloadDocumentByUrl.error) {
      setMessage({
        type: "error",
        title: "Error",
        description: "Hubo un error al descargar el archivo.",
      });
      setLoading((prev) => ({ ...prev, download: false }));
    }
  }, [
    getDescriptionDoc.error,
    updateDescriptionDoc.error,
    getAllDocuments.error,
    getUrlDocument.error,
    downloadPdf.error,
    deleteDocument.error,
    isFavoriteDocument.error,
    downloadDocumentByUrl.error,
  ]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderModalEliminar = () => {
    if (modalActiveEliminar) {
      return (
        <div>
          <ModalConfirm
            centered
            okText="Eliminar"
            handleCancel={closeEliminarModal}
            handleOK={EliminarCloseModal}
            closeModal={closeEliminarModal}
            modalActive={modalActiveEliminar}
            text="¿Estas seguro que deseas Eliminar este archivo? Esta acción no se puede deshacer"
          />
        </div>
      );
    }
    return null;
  };

  // Las columnas de la tabla
  const columns = [
    {
      title: <span className="font-medium text-center text-xl ">Código</span>,
      dataIndex: "code",
      key: "code",
      align: "center",
      render: (text) => (
        <span className="  text-center text-sizeBase text-black_custom  text-[14px]">
          {text}
        </span>
      ),
      className: "border-b-0",
    },
    {
      title: (
        <span className="font-medium text-center text-xl tour-step-1">
          Nombre del archivo
        </span>
      ),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text) => (
        <span className="  text-center text-sizeBase text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      width: 250,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: (
        <span className="font-medium text-center text-xl">Descripción</span>
      ),
      dataIndex: "description",
      ellipsis: true,
      key: "description",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      width: 150,
      className: "border-b-0",
    },
    ownerView
      ? {
        title: (
          <span className="font-medium text-center text-xl">Propietario</span>
        ),
        dataIndex: "owner",
        key: "owner",
        align: "center",
        render: (text) => (
          <span className="rounded-md text-center text-black_custom ">
            <Avatar size={16} icon={<UserOutlined />} /> {text}
          </span>
        ),
        className: "border-b-0",
        width: 150,
      }
      : {},
    {
      title: (
        <span className="font-medium text-center text-xl">Tipo de archivo</span>
      ),
      dataIndex: "fileExtension",
      key: "fileExtension",
      align: "center",
      render: (text) => (
        <span className=" rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      filters: [
        {
          text: "csv",
          value: ".csv",
        },
        {
          text: "web",
          value: "web",
        },
        {
          text: "xlsx",
          value: ".xlsx",
        },
        {
          text: "xls",
          value: ".xls",
        },
        {
          text: "pdf",
          value: ".pdf",
        },
      ],
      onFilter: (value, record) => record.fileExtension.indexOf(value) === 0,
      sorter: (a, b) => a.fileExtension.localeCompare(b.fileExtension),
    },
    {
      title: (
        <span className="font-medium text-center text-xl ">Tipo de fuente</span>
      ),
      dataIndex: "docType",
      key: "docType",
      align: "center",
      render: (text) => (
        <span className=" rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      filters: filterTypeDoc,
      onFilter: (value, record) => record.docType.indexOf(value) === 0,
      sorter: (a, b) => a.docType.localeCompare(b.docType),
    },
    {
      title: <span className="font-medium text-center text-xl">Tamaño</span>,
      dataIndex: "size",
      key: "size",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom">
          {formatSize(text)}
        </span>
      ),
      className: "border-b-0",
      sorter: (a, b) => a.size - b.size,
    },
    {
      title: (
        <span className=" font-medium text-center text-xl tour-step-2">
          Fecha de carga
        </span>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text) => (
        <span className="rounded-md text-center text-black_custom ">
          {text}
        </span>
      ),
      className: "border-b-0",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: <span className="font-medium text-center text-xl ">Acciones</span>,
      key: "acciones",
      align: "center",
      render: (text, record) => (
        <div className="flex  space-x-2 justify-center items-center">
          <Button
            className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
            icon={
              record.isFavorite ? (
                <StarFilled style={{ color: "yellowgreen" }} />
              ) : (
                <StarOutlined />
              )
            }
            onClick={(e) => {
              onFaviroteDocument(record);
              e.stopPropagation();
            }}
          />
          <ValidatePermissionUserOwner
            container={false}
            func="actionsTable"
            permission={["edit", "delete", "download"]}
            modulePermission="document_management"
            emailCreatorFile={record.userDetails?.email}
          >
            {/* {ValidateUserOwner(record.idUser) && ( */}
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none "
              icon={
                !loading.description?.[record.key] ? <EditOutlined /> : <Spin />
              }
              onClick={(e) => {
                // eslint-disable-next-line dot-notation
                onEdit(record.key);
                e.stopPropagation();
              }}
            />

            {/* TODO: Function to delete/deactivate file */}
            {/* {ValidateUserOwner(record.idUser) && ( */}
            <Button
              className="text-lightBlue2 shadow-xl w-12 hover:text-lightBlue hover:shadow-none"
              icon={
                !loading.delete?.[record.key] ? <DeleteOutlined /> : <Spin />
              }
              onClick={(e) => {
                onDeleted(record.key);
                e.stopPropagation();
              }}
            />

            {record.fileExtension !== "web" && (
              <Button
                className="text-lightBlue2 shadow-xl  w-12 hover:text-lightBlue hover:shadow-none "
                icon={
                  !loading.download?.[record.key] ? (
                    <DownloadOutlined />
                  ) : (
                    <Spin />
                  )
                }
                onClick={(e) => {
                  onDownloadDocument(record);
                  setInfoFile((prev) => ({
                    ...prev,
                    name: record.name,
                    extension: record.fileExtension,
                  }));
                  e.stopPropagation();
                }}
              />
            )}
          </ValidatePermissionUserOwner>
        </div>
      ),
      width: 150,
      className: "border-b-0",
    },
  ];

  const renderModal = () => {
    if (modalActive) {
      return (
        <AppModal
          open={modalActive}
          onCancel={closeModal}
          footer={false}
          className=" text-lightBlue2 text-lg text-center w-full lg:w-1/2"
        >
          <DocumentFormTemplate
            listColumns={listColumns}
            listMunicipalities={listMunicipalities}
            listCodification={listCodification}
            documentKey={1}
            action={action}
            dataDescription={dataDescription}
            handlerSubmit={handlerSubmitDescription}
            loading={loading.descriptionModal}
            hasTitle
          />
        </AppModal>
      );
    }
    return null;
  };

  return (
    <div className=" bg-white_custom flex flex-col items-center ">
      <div className=" w-full  bg-white_custom p-6 border border-colorBorder/25 shadow-2xl">
        <div>
          <FormAutoSearch
            loading={loading.search}
            submitSearch={handlerSearch}
            resetValue={!isSearch}
          />
          {isSearch && (
            <div style={{ textAlign: "right" }}>
              <Button
                shape="round"
                size="large"
                type="primary"
                className="font-Poppins shadow-lg rounded-full bg-lightBlue text-white_custom"
                onClick={onResetSearch}
              >
                Restablecer búsqueda
              </Button>
            </div>
          )}
        </div>

        <div className="flex justify-center items-center mb-4 tour-step-3">
          <AppTitle
            title="Datos Cargados"
            level={2}
            className="text-lightBlue2 font-bold text-center"
          />
        </div>
        {!loading.list ? (
          <TableTemplate
            enableScroll
            data={data}
            handlePageChange={handlePageChange}
            total={metadata.total}
            columns={columns}
            page_size={metadata.page_size}
            currentPage={metadata.page}
            className=" "
            onSelectRow={onClickRow}
            isClickRowActive
          />
        ) : (
          <div className="flex justify-center items-center h-56">
            <Spin />
          </div>
        )}
        {renderModal()}
      </div>
      {RenderModalEliminar()}

      <ModalConfirm
        centered
        okText="Aceptar"
        handleCancel={closeConfirmModal}
        handleOK={confirmCloseModal}
        closeModal={closeConfirmModal}
        modalActive={modalActiveConfirm}
        text="¿Estas seguro que deseas cerrar el proceso de descripción del archivo? Si lo haces igual puedes retomar el proceso después"
      />

      {/* Agregar el componente Tour */}
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>
  );
};
export default DocumentListTemplate;
