import { useState, useEffect } from "react";
// import Tour from "reactour";
import { Spin, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import useApi from "../../api/useApi";
import ListTemplate from "../../components/templates/ListTemplate";
// import investigationRequest from "../../api/investigationRequest";
import research from "../../api/research";
import ROUTES from "../../constant/router";


const  { Content } = Layout;
const Investigation = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState({});
  const [metadata, setMetaData] = useState({
    total: 10,
    page: 1,
    page_size: 10,
    totalPages: 1,
  });
  // const [isTourOpen, setIsTourOpen] = useState(false);
  const navigate = useNavigate();
  const getAllResearch = useApi(research.getAllResearchMeta);

  // const getDataTable = () => {
  //   const datainvestigacion = investigationRequest.getResearch();
  //   setData(datainvestigacion);
  //   setLoading((prev) => ({ ...prev, list: false }));
  // };

  useEffect(() => {
    // hacer la petición a la API para obtener los datos de la página
    getAllResearch.request(metadata.page_size, metadata.page, "published");
    setLoading((prev) => ({ ...prev, list: true }));
  }, []);

  // useEffect(() => {
  //   setLoading((prev) => ({ ...prev, list: true }));
  //   getDataTable();
  // }, []);

  // const handlePageChange = (page) => {
  //   // hacer la petición a la API para obtener los datos de la página
  //   setCurrentPage(page);
  // };
  useEffect(() => {
    if (getAllResearch.data) {
      const documents = getAllResearch.data.data.items;
      setData(documents);
      // const pagination = getAllResearch.data?.meta;
      // const auxData = documents.map((item) => ({
      //   ...item,
      //   key: item.id,
      //   createdAt: convertDate(item.createdAt),
      // }));
      // setTotal(getAllResearch.data.data.total);
      // setLoading((prev) => ({ ...prev, list: false }));

      getAllResearch.setData();
      setLoading((prev) => ({ ...prev, list: false }));
    }
  }, [getAllResearch.data]);
  const onClickRow = (item) => {
    // eslint-disable-next-line no-underscore-dangle
    const path = ROUTES.GENERAL.INVESTIGATION_INFO.replace(":key", item._id);
    navigate(path);
  };

  const handlePageChange = (page) => {
    setMetaData((prev) => ({ ...prev, page }));
  };

  // const steps = [
  //   {
  //     selector: ".title-views",
  //     content: "This is the title of the investigations page.",
  //   },
  //   {
  //     selector: ".list-template",
  //     content: "Here you can see the list of investigations.",
  //   },
  //   {
  //     selector: ".pagination",
  //     content: "Use this pagination to navigate through the investigations.",
  //   },
  // ];

  return (
    <Layout>
      <Content>
        <div className="flex flex-col justify-center items-center ">
          <h4 className="title-views my-5">Investigaciones</h4>
          {/* <div className="w-9/12">
        <Button
          type="primary"
          onClick={() => setIsTourOpen(true)}
          className="mb-4"
        >
          Start Tour
        </Button>
      </div> */}
          <div className="w-9/12 bg-white_custom p-6 rounded-[45px] border border-colorBorder/25 shadow-2xl">
            {loading.list ? (
              <Spin />
            ) : (
              <ListTemplate
                className="list-template"
                data={data}
                onClickRow={onClickRow}
                currentPage={metadata.page}
                total={metadata.total}
                page_size={metadata.page_size}
                handlePageChange={handlePageChange}
                hasPagination
              />
            )}
          </div>
          {/* <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      /> */}
        </div>
      </Content>
    </Layout>
  );
};

export default Investigation;
