import { Hub } from "aws-amplify/utils";
import { useEffect } from "react";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import token from "../api/token";
// import token from "../api/token";

const useAuthMonitor = () => {
  const { pathname } = useLocation();
  const idToken = token.getToken();
  const isAuthenticated = !!idToken;

  const { setCurrentUser, logout, refreshDataUser, setMessage, currentUser } =
    useAuth();
  const navigate = useNavigate();

  const getCurrentSession = async () => {
    
    if (
      !pathname.includes("recovery_password") &&
      !pathname.includes("/login") &&
      !pathname.includes("/register")
    ) {
      try {
        const { tokens: session } = await fetchAuthSession();
        const accessToken = session.idToken.toString();
        token.saveToken(accessToken);
        refreshDataUser();
      } catch (e) {
        // if (e.name === "UserUnAuthenticatedException") {
        // logout()
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line no-console
      console.log(event, data);
      switch (event) {
        case "signedIn":
          setMessage({
            type: "success",
            title: "Sesión iniciada",
            description: "Su sesión ha iniciado correctamente.",
          });
          navigate("/dashboard");
          break;
        case "signedOut":
          navigate("/login");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("firstName");
          sessionStorage.removeItem("lastName");
          sessionStorage.removeItem("email");
          sessionStorage.removeItem("sub");
          sessionStorage.removeItem("permissions");
          setCurrentUser(null);
          token.removeToken();
          break;
        case "customOAuthState":
          // console.log(data);
          break;
        case "tokenRefresh":
          getCurrentSession();
          break;
        case "tokenRefresh_failure":
          logout();
          navigate("/login");
          break;
        default:
          // console.log("🚀 ~ unsubscribe ~ event:", event);
      }
    });
    if (
      !isAuthenticated ||
      (currentUser && !currentUser.role) ||
      !currentUser
    ) {
      getCurrentSession();
    }
    return unsubscribe;
  }, [pathname]);
};

export default useAuthMonitor;
