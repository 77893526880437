import ROUTES from "./router";

const TABS = {
  "Usuario General": [
    {
      label: "Inicio",
      key: ROUTES.GENERAL.HOME,
    },
    {
      label: "Investigaciones publicadas",
      key: ROUTES.GENERAL.INVESTIGATION,
    },
  ],
  "Funcionario de Gobierno": [
    {
      label: "Inicio",
      key: ROUTES.GENERAL.HOME,
    },
    {
      label: "Gestión de archivos",
      key: ROUTES.GENERAL.DOCUMENTS,
    },
    {
      label: "Informes",
      key: ROUTES.GENERAL.STATEMENT,
    },
    {
      label: "Informe de indicadores trazadores",
      key: ROUTES.GENERAL.STATEMENT_INDICATORS,
    },
    {
      label: "Reportes",
      key: ROUTES.GENERAL.REPORT,
    },
    {
      label: "Investigaciones publicadas",
      key: ROUTES.GENERAL.INVESTIGATION,
    },
    {
      label: "Historial",
      key: ROUTES.GENERAL.HISTORY,
    },
    {
      label: "Análisis comparativo",
      key: ROUTES.GENERAL.COMPARATIVE_ANALYSIS,
    },
  ],
  Investigador: [
    {
      label: "Inicio",
      key: ROUTES.GENERAL.HOME,
    },
    {
      label: "Gestión de archivos",
      key: ROUTES.GENERAL.DOCUMENTS,
    },
    {
      label: "Informes",
      key: ROUTES.GENERAL.STATEMENT,
    },
    {
      label: "Informe de indicadores trazadores",
      key: ROUTES.GENERAL.STATEMENT_INDICATORS,
    },
    {
      label: "Reportes",
      key: ROUTES.GENERAL.REPORT,
    },
    {
      label: "Investigaciones publicadas",
      key: ROUTES.GENERAL.INVESTIGATION,
    },
    {
      label: "Investigaciones",
      key: ROUTES.GENERAL.INVESTIGATION_ADMIN,
    },
    {
      label: "Historial",
      key: ROUTES.GENERAL.HISTORY,
    },
    {
      label: "Análisis comparativo",
      key: ROUTES.GENERAL.COMPARATIVE_ANALYSIS,
    },
  ],
  Administrador: [
    {
      label: "Inicio",
      key: ROUTES.GENERAL.HOME,
    },
    {
      label: "Gestión de archivos",
      key: ROUTES.GENERAL.DOCUMENTS,
    },
    {
      label: "Informes",
      key: ROUTES.GENERAL.STATEMENT,
    },
    {
      label: "Informe de indicadores trazadores",
      key: ROUTES.GENERAL.STATEMENT_INDICATORS,
    },
    {
      label: "Reportes",
      key: ROUTES.GENERAL.REPORT,
    },
    {
      label: "Gestión de postulaciones",
      key: ROUTES.GENERAL.INDICATORS,
    },
    {
      label: "Gestión de taxonomías",
      key: ROUTES.GENERAL.INDICATORS_ADMIN,
    },
    {
      label: "Gestión de usuarios",
      key: ROUTES.GENERAL.USERS_LIST,
    },
    {
      label: "Investigaciones publicadas",
      key: ROUTES.GENERAL.INVESTIGATION,
    },
    {
      label: "Investigaciones",
      key: ROUTES.GENERAL.INVESTIGATION_ADMIN,
    },
    {
      label: "Análisis comparativo",
      key: ROUTES.GENERAL.COMPARATIVE_ANALYSIS,
    },
    {
      label: "Solicitudes",
      key: ROUTES.GENERAL.APPLICATIONS,
    },
    {
      label: "Historial",
      key: ROUTES.GENERAL.HISTORY,
    },
    /* {
      label: "Entidades",
      key: ROUTES.GENERAL.ENTITIES,
    }, */
  ],
  "Super Administrador": [
    {
      label: "Inicio",
      key: ROUTES.GENERAL.HOME,
    },
    {
      label: "Gestión de archivos",
      key: ROUTES.GENERAL.DOCUMENTS,
    },
    {
      label: "Informes",
      key: ROUTES.GENERAL.STATEMENT,
    },
    {
      label: "Informe de indicadores trazadores",
      key: ROUTES.GENERAL.STATEMENT_INDICATORS,
    },
    {
      label: "Reportes",
      key: ROUTES.GENERAL.REPORT,
    },
    {
      label: "Gestión de postulaciones",
      key: ROUTES.GENERAL.INDICATORS,
    },
    {
      label: "Gestión de taxonomías",
      key: ROUTES.GENERAL.INDICATORS_ADMIN,
    },
    {
      label: "Gestión de usuarios",
      key: ROUTES.GENERAL.USERS_LIST,
    },
    {
      label: "Investigaciones publicadas",
      key: ROUTES.GENERAL.INVESTIGATION,
    },
    {
      label: "Investigaciones",
      key: ROUTES.GENERAL.INVESTIGATION_ADMIN,
    },
    {
      label: "Análisis comparativo",
      key: ROUTES.GENERAL.COMPARATIVE_ANALYSIS,
    },
    {
      label: "Solicitudes",
      key: ROUTES.GENERAL.APPLICATIONS,
    },
    {
      label: "Historial",
      key: ROUTES.GENERAL.HISTORY,
    },
    /* {
      label: "Entidades",
      key: ROUTES.GENERAL.ENTITIES,
    }, */
  ],
};

export default TABS;
